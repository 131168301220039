import { Notification, toast } from '@hapstack/ui'
import {
  unstable_defineAction as defineAction,
  unstable_defineLoader as defineLoader,
} from '@remix-run/node'
import { useSearchParams } from '@remix-run/react'
import { useEffect } from 'react'

import { Description } from '~/components/Description'
import { Headline } from '~/components/Headline'
import { LoginButton } from '~/components/LoginButtons'
import { useClientEnvironmentVariables } from '~/hooks/useClientEnvironmentVariables'
import { authenticator, providerSchema } from '~/services/auth/auth.server'

export const loader = defineLoader(async ({ request }) => {
  await authenticator.isAuthenticated(request, { successRedirect: '/' })
  return null
})

export const action = defineAction(async ({ request }) => {
  const formData = await request.clone().formData()
  const provider = providerSchema.parse(formData.get('provider'))

  return await authenticator.authenticate(provider, request, {
    successRedirect: '/',
    failureRedirect: '/login',
  })
})

export default function LoginRoute() {
  const { CONSTANTS } = useClientEnvironmentVariables()
  const [searchParams] = useSearchParams()
  const context = searchParams.get('context')

  useEffect(() => {
    try {
      chrome.runtime
        .sendMessage(CONSTANTS.CHROME_EXTENSION_ID, { type: 'healthcheck' })
        .then((response) => {
          if (response.received) return
          toast.error('Please install our extension to continue.')
        })
    } catch (e) {
      toast.error('Please install our extension to continue.')
    }
  }, [CONSTANTS.CHROME_EXTENSION_ID])

  return (
    <div className="space-y-6">
      {context === 'update' ? (
        <Notification
          variant="info"
          icon="bell-ring"
        >
          An update to the {CONSTANTS.APP_NAME} extension requires you to log in
          again. We apologize for the trouble.
        </Notification>
      ) : null}
      <div className="space-y-4">
        <Headline>Log in</Headline>
        <Description>
          Once you're logged in, you may close this tab.
        </Description>
      </div>
      <div className="space-y-4">
        <LoginButton provider="google" />
        <LoginButton provider="microsoft" />
      </div>
    </div>
  )
}
